<template>
  <div class="error-container">
    <div class="error-message">
      <h2>404</h2>
      <span>Page Not Found.</span>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style>
  .error-container {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
</style>